import { defineStore } from 'pinia'
import type {
  Country,
  CountryPagedResult,
  Region,
  RegionDetail,
  RegionPagedResult,
} from '~/types/api'
import type { AutocompleteItem } from '~/types/form'
import { APIFilters } from '~/utils/APIFilters'

export const useCountriesStore = defineStore('countries', () => {
  const countriesRepository = createCountriesRepository()

  const { loadList } = useCache()
  const { t } = useI18n()

  const fetchedCountries = ref<Country[]>([])
  const countriesLoading = ref(false)
  const fetchedRegions = ref<Region[]>([])
  const regionsLoading = ref(false)

  const countries = computed(() => {
    return fetchedCountries.value.map(
      (country: Country) =>
        ({
          value: country.nid,
          text: t(`countries.country.${country.isoCode?.toUpperCase()}`),
        }) as AutocompleteItem
    )
  })

  const regions = computed(() => {
    const regions = {} as Record<string, AutocompleteItem[]>

    fetchedRegions.value.forEach((region: Region) => {
      const countryNid = region.countryId
      if (regions[countryNid] === undefined || regions[countryNid] === null) {
        regions[countryNid] = []
      }
      regions[countryNid].push({
        value: region.nid,
        text: region.title,
      })
    })

    return regions
  })

  const countriesPromise = ref<Promise<Country[]> | null>(null)
  function loadCountries(): Promise<Country[]> {
    const params = {
      sort: APIFilters.makeSort({ nid: 'ASC' }),
    }
    const apiCall = countriesRepository.getAll.bind(countriesRepository, params)

    return loadList<Country, CountryPagedResult>(
      fetchedCountries,
      countriesPromise,
      countriesLoading,
      apiCall
    )
  }

  const regionsPromise = ref<Promise<RegionDetail[]> | null>(null)
  function loadRegions(): Promise<RegionDetail[]> {
    const params = {
      sort: APIFilters.makeSort({ nid: 'ASC' }),
    }
    const apiCall = countriesRepository.getAllRegions.bind(
      countriesRepository,
      params
    )

    return loadList<Region, RegionPagedResult>(
      fetchedRegions,
      regionsPromise,
      regionsLoading,
      apiCall
    )
  }

  function getCountryByFlagCode(flagCode: string): Country | undefined {
    return fetchedCountries.value.find(
      (country: Country) => country.flagCode === flagCode
    )
  }

  async function getCountryIdForCode(code?: string): Promise<Country | null> {
    if (
      !fetchedCountries.value ||
      countriesPromise === null ||
      code === undefined
    ) {
      // store not inited
      return null
    }

    await countriesPromise
    const foundResponse = Object.values(fetchedCountries.value ?? {}).find(
      (country) => country.flagCode === code
    )
    if (foundResponse === undefined) {
      return null
    }
    return foundResponse
  }

  // have to return fetched countries and regions despite them being private otherwise store would not work properly
  return {
    fetchedCountries,
    countriesLoading,
    fetchedRegions,
    regionsLoading,
    countries,
    regions,
    loadCountries,
    loadRegions,
    getCountryByFlagCode,
    getCountryIdForCode,
  }
})
